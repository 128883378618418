.icon {
	width: 2em;
	height: 2em;
}

.icon--small {
	width: 1em;
	height: 1em;
}

.icon--large {
	width: 3em;
	height: 3em;
}

.add-information-icon {
	color: #b42471;

	border: solid 2px #b42471;
	border-radius: 10px;
	height: 20px;
	width: 20px;
  line-height: 13px;
	font-family: 'Patua One', cursive;

	&:after {
		content: "i";
	}
}
