.dollar-amount-monthly {
	position: relative;
	color: inherit;

	& > .post-number {
		font-size: 24px;
		font-weight: bold;
	}

	& > .number {
		position: relative;
		font-size: 64px;
		margin-right: 10px;

		&:before {
			position: absolute;
			top: 5px;
			left: -15px;

			content: "$";
			font-size: 24px;
			font-weight: bold;
		}

		&:after {
			position: absolute;
			bottom: 10px;
			right: -45px;

			content: "/mo";
			font-size: 24px;
			font-weight: bold;
		}
	}
}
