$invert-text-color: rgba(255, 255, 255, 0.95);

main.pricing {
	color: #16536f;

	.show-md {
		@media screen and (min-width: 1000px) {
			display: none;
		}
	}

	.hide-md {
		@media screen and (max-width: 999px) {
			display: none;
		}
	}

	.intro {
		h3 { margin-bottom: 0px; }
		p { margin-top: 0px; }

		.subtitle {
			font-size: responsive 20px 24px;
			letter-spacing: -0.6px;
			color: rgba(23, 86, 115, 0.95);
		}

		a {
			letter-spacing: -0.7px;
			color: #b42471;
		}

		margin-bottom: 20px;
	}

	.pricing-list {
		background-color: #f8f8f8;
		text-align: center;

		@media screen and (max-width: 767px) {
			padding-bottom: 30px;
		}

		ul {
			list-style: none;
		}

		li {
			color: rgba(23, 86, 115, 0.65);
			line-height: 62px;
		}

		.pricing-categories,
		.pricing-item {

			li span {
				font-size: 22px;
				line-height: 1.27;
				letter-spacing: -0.5px;
				text-align: center;
			}

			li {
				border-top: solid 1px rgba(29, 96, 128, 0.25);
			}

			ul {
				padding: 0px;
				margin: 0px;
			}
		}

		.pricing-categories {
			border: solid 1px rgba(42, 119, 155, 0.25);
			max-height: 399px;
			text-align: left;

			h5 {
				margin: 0px;
				padding: 0px;
			}

			.type {
				font-size: responsive 22px 24px;
				font-weight: bold;
				letter-spacing: 1px;
				color: rgba(255, 255, 255, 0.95);
			}

			.popover-wrapper {
				position: relative;

				.popover-content {
					display: none;
					opacity: 0;
					background-color: #0dbdae;
					font-size: responsive 16px 18px;
					letter-spacing: 0.8px;
					line-height: 1.3em;
					color: rgba(255, 255, 255, 0.95);
					padding: 0px 10px 0px 15px;
					width: 150%;
					margin: 0 -25%;
					margin-top: 15px;
					border-radius: 4px;
				}

				&:hover .popover-right {
					z-index: 1000;
					display: block;
			    opacity: 1;
					position: absolute;
					right: 0px;
			    visibility: visible;
			    transform: translate(0,-20px);
			    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
				}

				&:hover .add-information-icon {
					&:before {
						position: absolute;
						// top: -15px;
						width: 0;
					  height: 0;
						content: "";
						margin: 0 -5%;
						margin-top: 18px;
					  border-left: 15px solid transparent;
					  border-right: 15px solid transparent;

					  border-bottom: 15px solid #0dbdae;
					}
				}

				.popover__content {
				    opacity: 0;
				    visibility: hidden;
				    position: absolute;
				    left: 0px;
				    transform: translate(0,10px);
				    background-color: #BFBFBF;
				    padding: 1.5rem;
				    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
				    width: auto;
				}

				.popover__content:before {
			    // position: absolute;
			    // z-index: -1;
			    // content: '';
			    // right: calc(50% - 10px);
			    // top: -8px;
			    // border-style: solid;
			    // border-width: 0 10px 10px 10px;
			    // border-color: transparent transparent #BFBFBF transparent;
			    // transition-duration: 0.3s;
			    // transition-property: transform;
				}

				.popover-message {
					text-align: left;
				}
			}

			li {
				padding-left: 15px;

				@media screen and (max-width: 1200px) {
					span {
						font-size: 16px;
					}
				}
			}

			header {
				background-color: rgba(32, 149, 207, 0.75);
				padding: 20px 0px 20px 15px;
				border-top-left-radius: 4px;
			}

			@media screen and (min-width: 1000px) {
				max-width: 268px;
				flex: 1;
			}

			@media screen and (max-width: 999px) {
				display: none;
			}
		}

		.table {
			display: flex;
			justify-content: center;
			align-items: flex-end;

			.item--personal header {
				border-top-left-radius: 4px;
			}

			.item--platinum header {
				border-top-right-radius: 4px;
			}
		}

		.pricing-item {


			@media screen and (min-width: 1000px) {
				max-width: 224px;
				flex: 1;

				&:not(.item--advanced) {
					margin-top: 42px;
				}

				&:not(:last-child) header {
					border-right: solid 1px #979797;
				}

				&.item--gold header {
					border-left: solid 1px #979797;
				}

				&.item--advanced header {
					border-top-right-radius: 4px;
					border-top-left-radius: 4px;
					border: unset;
				}

				border-bottom: solid 1px rgba(29, 96, 128, 0.25);

				&:last-child ul {
					border-right: solid 1px rgba(29, 96, 128, 0.25);
				}

				header h5 {
					padding-top: 15px;
				}

				header {
					padding-bottom: 15px;
				}
			}

			@media screen and (max-width: 999px) {
				width: 256px;
				margin: 0 auto;
				margin-bottom: 64px;
				border: solid 1px rgba(29, 96, 128, 0.25);
				border-radius: 4px;

				header {
					border-top-right-radius: 4px;
					border-top-left-radius: 4px;
				}
			}

			header {
				background-color: #2a779b;
				color: $invert-text-color;
				font-weight: 400;


				.tag {
					background-color: #0dbdae;
					line-height: 40px;
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0.8px;
					border-top-right-radius: 4px;
					border-top-left-radius: 4px;
					border: solid 1px #0dbdae;
				}

				@media screen and (max-width: 999px) {
					h5 {
						padding-top: 20px;
					}

					.dollar-amount-monthly {
						padding-bottom: 20px;
					}
				}
			}

			h5 {
				margin: 0px;
				color: rgba(255, 255, 255, 0.75);
				font-size: 24px;
				font-weight: bold;
				letter-spacing: 0.2px;
			}

			.dollar-amount-monthly {
				line-height: normal;
				margin-bottom: 0px;
			}
		}
	}



	.extras {
		color: $invert-text-color;
		background-image: linear-gradient(to right, #2195cf, #21c0cf);
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

		@media screen and (min-width: 768px) {
			padding: 40px 0px 30px 0px;
		}

		@media screen and (max-width: 767px) {
			padding: 20px 0px 30px 0px;
			ul { margin: 0; }
		}

		.extras-list-container {
			max-width: 850px;

			@media screen and (max-width: 767px) {
				max-width: 400px;
			}
		}

		h3 {

		}

		li {
			padding: 5px 0px;
			font-size: responsive 18px 22px;
			line-height: 1.27em;
		}
	}

	.faq {
		background-color: #e9f4f5;
		padding: 50px 24px 100px 24px;

		.question-item:nth-child(odd) {
			@media screen and (min-width: 768px) {
				padding-right: 30px;
			}
		}

		.question-item:nth-child(even) {
			@media screen and (min-width: 768px) {
				padding-right: 0px;
			}
		}

		.question-item {
			@media screen and (min-width: 768px) {
				padding-left: 0px;
			}

			@media screen and (max-width: 767px) {
				padding-left: 0px;
				padding-right: 0px;
			}

			padding-bottom: 20px;
		}

		.h3 {
			margin-bottom: 100px;
		}

		h5 {
			span {
				font-size: responsive 20px 24px;
			}
		}

		p {
			span {
				font-size: responsive 18px 22px;
				color: rgba(23, 86, 115, 0.65);
			}
		}
	}
}
