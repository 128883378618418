figure.app-example {
	perspective: 800px;

	@media screen and (max-width: 767px) {
		margin: 0 auto;
	}

	& > img {
		transform-style: preserve-3d;
	}

	&.app-example--left {
		text-align: left;
		perspective-origin: left;

		img {
			@media screen and (min-width: 768px) {
				transform: rotateY(20deg);
			}

			@media screen and (max-width: 767px) {
				transform: rotateY(25deg);
			}
			
			box-shadow: -21px 22px 40px 0 rgba(0, 0, 0, 0.3);
		}
	}

	&.app-example--right {
		text-align: right;
		perspective-origin: right;

		img {
			@media screen and (min-width: 768px) {
				transform: rotateY(-20deg);
			}

			@media screen and (max-width: 767px) {
				transform: rotateY(-25deg);
			}
			
			box-shadow: 21px 22px 40px 0 rgba(0, 0, 0, 0.3);
		}
	}
}
