main.about {
	@media screen and (min-width: 768px) {
		padding-top: 96px;
		padding-bottom: 72px;
	}

	@media screen and (max-width: 767px) {
		padding-top: 45px;
		padding-bottom: 35px;
	}

	iframe {
		width: 100%;
		max-width: 312px;
	}

	.about-us {
		padding-top: 40px;
	}

	.contact {
		padding-top: 30px;

		a {
			color: inherit;
		}
	}

	.contact-wrap {
		@media screen and (min-width: 768px) {
			padding-left: 15px
		}
	}

	.map-container {
		@media screen and (max-width: 768px) {
			height: 300px;
			margin-bottom: 2em;
		}
	}

	.section-title {
		font-size: responsive 20px 24px;
		font-weight: normal;
		line-height: 1.54em;
		letter-spacing: 3px;
		color: rgba(38, 43, 77, 0.8);
	}

	.section-description {
		color: rgba(25, 29, 51, 0.7);
		line-height: 1.5em;
		font-size: responsive 16px 18px;
	}

	
	.page-section {
		max-width: 742px;
		margin: 0 auto;

		img {
			width: 100%;
		}
	}
}
