$header-background-color: rgba(138, 195, 73, 0.90);
$nav-background-color: #145e82;
$nav-link-color: rgba(255, 255, 255, 0.7);
$nav-link-color-active: #ffffff;

/* Header Styles
=====================*/

.page > header {
	z-index: 2000;
	background: url(
		data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPUlEQVQoU2Ncc7v8f4hqJyMDAQBWQIxiuEmEFKNYiU8xhttwKcbqCWyKcfoWXTHeYEFWTDD8YIoJKoSFMwDs/CcIuPUuJQAAAABJRU5ErkJggg==
	) repeat;
	background-color: $header-background-color;

	

	ul, li {
		margin: 0;
		padding: 0;
	}

	.nav-container {
		display: flex;
		flex-direction: row;
		margin: 0 auto;
		max-width: 1170px;
		
		@media screen and (max-width: 767px) {
			height: 64px;
		}

		@media screen and (min-width: 768px) {
			height: 100px;
		}

		a {
			outline: none;
		}

		.brand {
			max-width: 180px;
		}

		.brand-logo {
			margin-top: -7px;

			@media screen and (max-width: 767px) {
				margin-top: 0px;
			}
		}

		.brand-title {
			// font-family: Roboto;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 3px;
			color: rgba(255, 255, 255, 0.8);
			padding-left: 25px;
			white-space: nowrap;
		}

		.call-to-action {
			justify-content: flex-end;
		}

		.nav-link,
		.brand {
			display: flex;
			-webkit-box-align: center;
			align-items: center;

			color: $nav-link-color;
			font-size: responsive 16px 18px;
			text-decoration: none;
			cursor: pointer;

			@media screen and (max-width: 767px) {
				padding: 0px 15px;
			}

			@media screen and (min-width: 768px) {
				padding: 0px 25px;
				font-weight: bold;
			}
		}

		.language-list {
			display: flex;
			flex-grow: 1;
			padding-right: 20px;
			align-items: center;
			justify-content: flex-end;
		}

		.nav-link {
			@media screen and (min-width: 768px) {
				height: 100%;
			}
		}

		.mobile-btn {
			@media screen and (max-width: 767px) {
				border-radius: 4px;
				border: solid 1px rgba(255, 255, 255, 0.8);
				line-height: 32px;
			}
		}

		.nav-link:hover {
			color: $nav-link-color-active;
		}

		.action-wrap {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: flex-end;

			.call-to-action {
				white-space: nowrap;
				margin-right: 20px;
			}
		}
	}

	.hide-mobile {
		@media screen and (max-width: 767px) {
			display: none !important;
		}
	}

	.show-mobile {
		@media screen and (min-width: 768px) {
			display: none !important;
		}
	}
}
