.page > footer {
	overflow: hidden;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;

	background-color: #595f7f;
	color: rgba(255, 255, 255, 0.8);
	text-align: center;
	padding-top: 10px;

	@media screen and (min-width: 768px) {
		height: 96px;
	}

	@media screen and (max-width: 767px) {
		height: 125px;
	}
	

	.container-fluid {
		max-width: 1170px;
		margin: 0 auto;

		@media screen and (max-width: 767px) {
			padding: 0px 24px;
		}

		@media screen and (min-width: 768px) {
			padding-top: 10px;
		}
	}

	nav {
		ul {
			li {
				@media screen and (min-width: 768px) {
					display: inline-block;
				}
			}
		}
	}

	.copyright-container {
		text-align: left;

		p {
			font-size: responsive 16px 20px;
			line-height: 1.3em;
			color: inherit;
		}

		@media screen and (max-width: 767px) {
			p { margin-bottom: 15px; }
		}
	}

	.social,
	.pages {
		display: flex;
		justify-content: space-around;
		
		a {
			display: flex;
			justify-content: center;
			flex-direction: column;
			height: 100%;

		  	&:hover {
				color: #ffffff;
				text-decoration: underline;
			}
		}

		@media screen and (max-width: 767px) {
			margin-top: 10px;
		}
	}

	.pages {
		@media screen and (max-width: 767px) {
		    justify-content: space-between;
    		max-width: 230px;
			
		}
	
		a {
			font-size: responsive 16px 20px;
			color: inherit;
		}
	}

	.social {
		justify-content: space-between;

		@media screen and (min-width: 768px) {
			// padding-left: 30px;
		}

		@media screen and (max-width: 767px) {
			max-width: 100px;
			padding-left: 12px;
			padding-right: 0px;
			margin-top: 5px;
		}

		a {
			display: block;
		}

		
		a:hover svg {
			fill: #ffffff;
		}
	}
}
