
/* Default Button
=================*/

a.btn {
	text-decoration: none;
	color: inherit;
}

.btn {
	display: inline-block;
	text-decoration: none;
	text-align: center;
	border-radius: 4px;
	font-weight: bold;

	&.default {
		color: #ffffff;
		background-color: #b42471;
		padding: 0px 50px;
		font-size: 18px;
		letter-spacing: 1px;
		line-height: 56px;

		&:hover {
			background-color: #CC2880;
		}

		&:active {
			background-color: #9E1F63;
		}

		&:disabled {
			background-color: #B5638F;
		}
	}

  &.clear {
		border: solid 2px #1d6080;

		font-size: 16px;

		color: #2a779b;
  }

  &:hover {
    &.clear {

    }
  }
}

.pricing .btn.clear {
	line-height: 56px;

	@media screen and (min-width: 768px) {
		width: 100%;

	}

	@media screen and (max-width: 767px) {
		max-width: 280px;
		min-width: 240px;
	}

	&:hover {
		background-color: #1d6080;
		color: #ffffff;
	}

	&:focus {
		background-color: #174D66;
		color: #ffffff;
	}

	&:disabled {
		background-color: #6B8B99;
		color: #ffffff;
	}
}

.btn.clear.call-to-action {
	border-radius: 4px;
	border: solid 2px rgba(255, 255, 255, 0.7);
	padding: 10px 30px;
	color: rgba(255, 255, 255, 0.7);
	font-size: 18px;
	font-weight: bold;

	&:hover {
		color: #ffffff;
		border-color: #ffffff;
	}
}
