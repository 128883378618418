main.reset {
    color: rgba(38, 43, 77, 0.6);

    .hint {
        font-size: 12px;
        color: rgba(23, 31, 76, 0.6);
    }

    .card {
        margin: 0 auto;
        border-radius: 3px;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 32px 32px 32px 32px;
    }

    #success-card {
        display: none;
        
        &.card {
            min-height: 170px;
            max-width: 248px;
            background-color: rgba(138, 195, 73, 0.9);
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            
            h3 {
                font-weight: normal;
                font-size: 20px;
	            line-height: 1.3;
                margin-top: 20px;
                margin-bottom: 0px;
            }
        }
    }

    #reset-card {
        &.card {
            min-height: 254px;
            max-width: 300px;
            background-color: #ffffff;
        }
    }

    input:after {
        position: absolute;
        right: 0px;
        top: 0px;

        content: '';
        width: 20px;
        height: 20px;
    }

    #ConPasswordGroup.success-active {
        
    }

    #NewPasswordGroup.error-active,
    #ConPasswordGroup.error-active {
        label, .hint {
            color: #ff0000;
        }

        .bar:before,
        .bar:after {
            width:50%;
            background-color: #ff0000;
        }

        input:after {
            background-image: url('../images/error.svg');
        }
    }

    .content-wrapper {
        margin: 0 auto;
        margin-top: 70px;
    }

    .header-image {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .brand-logo svg {
        fill: rgba(38, 43, 77, 0.6);
    }

    .card-title span {
        font-size: 24px;
        line-height: 1.54;
    }

    .error {
        display: none;
    }

    button.btn {
        color: #ffffff;
        border-radius: 3px;
	    background-color: #8bc34a;
        line-height: 40px;
        font-weight: normal;
        cursor: pointer;
        outline: none;
        
        &.btn-block {
            width: 100%;
        }
    }

    .form-signin,
    .form-input-group,
    input,
    label {
        width: 100%;
    }

    .form-input-group {
        position: relative;
        margin-bottom: 40px;
        font-size: 16px;
        padding: 0px;


        input {
            position: relative;
            padding: 10px 10px 10px 5px;
            border-bottom: 1px solid rgba(23, 31, 76, 0.8);

            &:focus { outline: none; }
        }

        label {
            pointer-events:none;

            position: absolute;
            left: 0px; top: 10px;
            padding: 0px;
            color: rgba(38, 43, 77, 0.38);

            transition: 0.2s ease all; 
        }

        /* active state */
        input:focus ~ label,
        input:valid ~ label {
            top: -20px;
            font-size: 12px;
            color:#8bc34a;
        }

        input:focus ~ .bar:before,
        input:focus ~ .bar:after {
            width:50%;
        }

        // input:focus ~ .highlight {
        //     animation: inputHighlighter 0.3s ease;
        // }

        .bar {
            position: relative;
            display: block;
            width: 100%;

            &:before, &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                height: 2px;
                width: 0;
                background-color: #8bc34a;
                transition: 0.2s ease all;
            }

            &:before {
                left: 50%;
            }

            &:after {
                right: 50%;
            }
        }

        // .highlight {
        //     position: absolute;
        //     top: 25%; left: 0;

        //     height: 60%; 
        //     width: 100%; 
            
        //     pointer-events: none;
        //     opacity: 0.5;
        // }

        // @keyframes inputHighlighter {
        //     from { background: #8bc34a; }
        //     to 	{ width: 0; background: transparent; }
        // }
    }
}