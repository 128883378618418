#ModalContainer {
	display: none;

	&.active {
		position: fixed;
		top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;

		display: block;

		#EmailModal {
			display: block;

			.error-icon,
			.success-icon {
				color: white;
			}

			input {
				color: rgba(74, 74, 74, 0.9);
			}

			input::placeholder {
	      color: rgba(74, 74, 74, 0.5);
	    }
		}
	}

	z-index: 1000;


	.backdrop {
		background-color: rgba(0, 0, 0, 0.0);
		transition: all 4s ease;

		&.active {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: -1;
			cursor: pointer;
			display: block;
		}
	}


/* Global Modals
===================*/

.modal {
	display: none;
	margin: 0 auto;
	margin-top: 120px;



	.signup-wrap {
		padding: unset;
	}

	&.active {
		display: block;
	}

	.btn-cancel,
	.btn-accept {
		font-size: 1.1rem;
		height: 45px;
	}

	&.animated.fadeIn,
	&.animated.fadeOut {
		animation-duration: 0.3s;
	}

	.header__title {
		margin: 0px;
	}

	&.modal__normal {
		color: #4a4a4a;
		max-width: 420px;
		height: auto;

		background-color: white;
		border-style: solid;
		border-width: 1px;
		border-radius: 6px;
		border-color: #979797;

		@media screen and (max-width: 442px) {
			margin-left: 5px;
			margin-right: 5px;
		}

		.header__title span {
			color: inherit;
		  font-size: responsive 1rem 1.5rem;
			font-weight: 700;
		}

		.modal__body p {
			color: inherit;
		  font-size: responsive 1rem 1.5rem;
		  // font-range: $content-min-width $content-max-width;
		}

		.btn {
			display: block;
			text-transform: capitalize;
			width: 100%;
			cursor: pointer;
		}

		input.in-primary {
			display: block;
			width: 100%;
		}

		.body__description {
			margin-top: 0px;
		}

		.modal__header {
			padding: 14px 30px;

			.modal__close {
				position: relative;
				float: right;

				height: 100%;
				cursor: pointer;

				img {
					position: absolute;
					top: 8px;
					right: 0;
				}
			}
		}

		.modal__body {
			padding: 0px 30px;

			p {
				margin: 10px 0px 0px 0px;
			}
		}

		.modal__footer {
			padding: 20px 30px 20px 30px;

			.signup-state {
				padding-left: 0px;
				padding-bottom: 10px;
			}

			// @media screen and (max-width: $screen-xs-max) {
			// 	.col-xs-12:last-child {
			// 		margin-top: 10px;
			// 	}
			// }
		}

		.text-only {
			padding-bottom: 18px;
		}

		.form-group {
			label {
				display: block;
			  font-size: 1rem;
				font-weight: 700;
				color: inherit;
				margin-bottom: 5px;
			}

			input,
			button {
				height: 100%;
				font-size: 1.1rem;
				outline: none;
			}

			input {
				font-weight: 300;
			}

			button {
				font-weight: 400;
			}

			.input-group {
				max-width: 360px;
				height: 40px;
			}

			.error {
				display: none;
				padding: 0px;
				margin: 8px 0px 0px 0px;
			}

			.error-active {
				display: block;
			}

			.in-inline,
			.btn-inline {
				display: inline-block;
				float: left;
			}

			.in-inline {
				width: 70%;
			}

			.btn-inline {
				width: 30%;
			}
		}
	}


/* Quote
===================*/

.get-quote {

}


/* Quote
===================*/

.get-quote {

}

}
}

#ComingSoonModal {
	.modal__footer {
		margin-top: 20px;
	}
}
