// /* Bug fixing mixins
// ======================*/
//
// %clearfix {
//   &:after {
//     content: "";
//     display: table;
//     clear: both;
//   }
// }
//
// /* Placeholders
// ======================*/
//
// %link-reset {
//   text-decoration: none;
//   color: inherit;
// }
//
// %center-absolute {
//   position: absolute;
//   top: 50%;
//   right: auto;
//   bottom: auto;
//   left: 50%;
//   transform: translate(-50%,-50%);
// }
//
// .center-absolute {
// 	@extend %center-absolute;
// }
//
// .pointer {
//   cursor: pointer;
// }
//
// .hidden {
//   display: none;
// }
//
// .modal-open {
//   overflow: hidden !important;
//   width: 100%;
//   position: fixed;
// }
//
// /* Hide by screen size
// ========================*/
//
// @media screen and (max-width: $screen-sm) {
//   .hidden-sm {
//     display: none !important;
//   }
// }
//
// @media screen and (max-width: $screen-md) {
//   .hidden-md {
//     display: none !important;
//   }
// }
//
// // @media screen and (min-width: $screen-md) {
// //   .hidden--md {
// //     display: none !important;
// //   }
// // }
//
// /*.hidden-phone {
//   @include breakpoint($phone) {
//     display: none !important;
//   }
// }
//
// .hidden-tablet {
//   @include breakpoint($tablet) {
//     display: none !important;
//   }
// }
//
// .hidden-laptop {
//   @include breakpoint($laptop) {
//     display: none !important;
//   }
// }
//
// .hidden-desktop {
//   @include breakpoint($desktop) {
//     display: none !important;
//   }
// }
//
// .hidden-lt-phone {
//   @include breakpoint($lt-phone) {
//     display: none !important;
//   }
// }
//
// .hidden-gt-phone {
//   @include breakpoint($gt-phone) {
//     display: none !important;
//   }
// }
//
// .hidden-lt-tablet {
//   @include breakpoint($lt-tablet) {
//     display: none !important;
//   }
// }
//
// .hidden-gt-tablet {
//   @include breakpoint($gt-tablet) {
//     display: none !important;
//   }
// }
//
// .hidden-lt-laptop {
//   @include breakpoint($lt-laptop) {
//     display: none !important;
//   }
// }
//
// .hidden-gt-laptop {
//   @include breakpoint($gt-laptop) {
//     display: none !important;
//   }
// }
//
// .hidden-lt-desktop {
//   @include breakpoint($lt-desktop) {
//     display: none !important;
//   }
// }
//
// .hidden-gt-desktop {
//   @include breakpoint($gt-desktop) {
//     display: none !important;
//   }
// }*/
//
// /* Visible by screen size
// ========================*/
//
// @media screen and (min-width: $screen-sm + 1) {
//   .visible-sm {
//     display: none !important;
//   }
// }
//
// @media screen and (max-width: $screen-sm) {
//   .visible-md {
//     display: none !important;
//   }
// }
//
// @media screen and (min-width: $screen-md + 1px) {
//   .visible-lt-md {
//     display: none !important;
//   }
// }

.pull-right {
  float: right;
}
