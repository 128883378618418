@import 'vendors/module';

@import 'base/module';
@import 'utils/module';

@import 'components/module';
@import 'layout/module';
@import 'pages/module';
// @import 'themes/module';

body, .page {
	min-height: 100%;
}

html {
	height: 100%;
	background-color: #faf9f8;
}

.page {
	position: relative;
	overflow: hidden;

	min-height: 100vh;

	background-color: #faf9f8;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(0, 0, 0, 0.08)), linear-gradient(rgba(255, 243, 224, 0.3), rgba(255, 243, 224, 0.3));

	.es { display: none; }

	&.hide-english {
		.en {
			display: none;
		}
	}

	&.english {
		span.en,
		a.en {
			display: inline;
		}

		article.en {
			display: block;
		}
	}

	&.espanol {
		span.es,
		a.es {
			display: inline;
		}

		article.es {
			display: block;
		}
	}



	@media screen and (min-width: 768px) {
		padding-bottom: 96px;
	}

	@media screen and (max-width: 767px) {
		padding-bottom: 125px;
	}
}

.hide-mobile {
	@media screen and (max-width: 767px) {
		display: none;
	}
}

.show-mobile {
	@media screen and (min-width: 768px) {
		display: none;
	}
}

.page-section {
	& > .row {
		max-width: 1200px;
		margin: 0 auto;
	}
}

article.document {
	max-width: 820px;
	padding: 80px 0px;
	margin: 0 auto;

	.title-special,
	.section-title {
		text-transform: uppercase;
	}

	.title-special {
		text-align: left;
	}

	.lettered-list {
		list-style: lower-alpha;
	}

	.contact-info {
		a {
			color: inherit;
		}
	}

	.roman-list {
		list-style: lower-roman;
	}

	.section-title {
		color: rgba(38, 43, 77, 0.8);
		font-size: responsive 20px 24px;
		font-weight: 500;
		letter-spacing: 3px;
		width: 100%;
	}

	.document-heading {
		margin-bottom: 30px;
		text-align: left;
		width: 100%;

		color: rgba(25, 29, 51, 0.8);

		h1 {
			margin-bottom: 0px;
		}

		h3 {
			margin-top: 0px;
		}

		h1 span,
		h3 span {
			font-weight: normal;
		}

		h1 span {
			color: inherit;
			font-size: responsive 24px 36px;
			letter-spacing: 1.4px;
		}

		h3 span {
			color: rgba(23, 86, 115, 0.65);
			font-size: responsive 20px 22px;
		}
	}

	p { display: block; width: 100%; }

	ol ol {
		@media screen and (min-width: 1000px) {
			padding-left: 80px;
		}
	}

	p span,
	ol,
	ul {
		line-height: 1.33em;
		color: rgba(25, 29, 51, 0.7);
		font-size: responsive 16px 18px;
	}
}
