
/* Features
====================*/

main.features {
	background-color: #e9f4f5;
	// padding: 80px 0px;

	ul { padding: 0px 0px 0px 20px; }

	section {
		background-color: #e9f4f5;
	}

	.title-normal {
		font-size: responsive 24px 36px;
		font-weight: bold;
		color: #175673;
	}

	.section-description,
	.section-list {
		font-size: responsive 20px 22px;
		line-height: 1.3em;
		letter-spacing: -0.5px;
		color: rgba(23, 86, 115, 0.95);
	}

	.section-list {

	}

	/* Summary of page
	====================*/

	.intro {
		position: relative;
		padding: 20px 24px 50px 24px;
		text-align: center;

		.btn {
			white-space: nowrap;
		}

		.title-special {
			color: #16536f;
			line-height: 1.4em;
			margin-bottom: 20px;
		}

		.subtitle {
			color: rgba(23, 86, 115, 0.95);
			font-size: responsive 20px 22px;
			font-weight: normal;
			line-height: 1.27em;
			letter-spacing: -0.5px;
			text-align: center;
			max-width: 570px;
			margin: 0 auto;

			@media screen and (min-width: 768px) {
				margin-bottom: 60px;
				padding: 0px 20px;
			}

			@media screen and (max-width: 767px) {
				margin-bottom: 40px;
				padding: 0px 30px;
			}
		}

		.col-xs-12 {
			z-index: 2;
		}

		&:after {
			position: absolute;
			content: "";
			top: 0;
			right: 0;
			height: 100%;
			width: 160px;
			background-image: url('../images/bkg-git-pattern-right.png');
			z-index: 1;
		}

		&:before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			height: 100%;
			width: 160px;
			background-image: url('../images/bkg-git-pattern-left.png');
			z-index: 1;
		}
	}

	.git-system {
		padding: 80px 24px;
		background-color: #f9f9f9;
		position: relative;
		overflow: hidden;

		.section-title {
			font-size: responsive 24px 36px;
			font-weight: bold;
			line-height: 1.2em;
			color: #175673;
		}

		.section-description {
			font-size: responsive 18px 22px;
			line-height: 1.27em;
			color: rgba(23, 86, 115, 0.95);
		}

		.git-tree-image-container {
			// background-image: url('../images/imgGitTree.png');
			z-index: 1 !important;

			@media screen and (max-width: 767px) {
				max-height: 200px;
			}
		}

		> .row,
		> .row .col-xs-12 {
			z-index: 1000;
		}

		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 120px;
			z-index: 1;
			left: 0;
		}

		&:before {
			top: 0;
			background-image: linear-gradient(to top, rgba(233, 243, 245, 0.0), rgba(243, 248, 248, 0.75) 54%, #e9f4f5);
		}

		&:after {
			bottom: 0;
			background-image: linear-gradient(to bottom, rgba(233, 243, 245, 0.0), rgba(243, 248, 248, 0.75) 54%, #e9f4f5);
			z-index: 1;
		}
	}

	// .git-system {
	// 	background: url(
	// 		data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAHklEQVQoU2Ncv+/ETAYiACMRahhAho0qxBlSJAUPAO5pHBcCX96qAAAAAElFTkSuQmCC
	// 	) repeat;
	// }

	/* Summary of page
	====================*/

	.build {
		background-color: #f9f9f9;

		position: relative;

		@media screen and (min-width: 768px) {
			padding: 60px 0px 100px 0px;

			.text-container {
				padding-left: 24px;
			}
		}

		@media screen and (max-width: 767px) {
			padding: 30px 24px;
		}

		.code-type {
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			color: #2a779b;

			@media screen and (max-width: 767px) {
				display: none;
			}
		}

		.img-code-container {
			width: 100%;
		}

		> .row,
		> .row .col-xs-12 {
			z-index: 1000;
		}

		.img-col {
			display: flex;
			align-items: center; /* align vertical */

			@media screen and (min-width: 1150px) {
				justify-content: flex-end;
			}

			@media screen and (max-width: 1149px) {
				.img-code-container {
					padding-left: 30px;
				}
			}

			@media screen and (max-width: 767px) {
				margin-top: 30px;
			}
		}

		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 120px;
			left: 0;
			z-index: 1;
		}

		&:before {
			top: 0;
			background-image: linear-gradient(to top, rgba(233, 243, 245, 0.0), rgba(243, 248, 248, 0.75) 54%, #e9f4f5);
		}

		&:after {
			bottom: 0;
			background-image: linear-gradient(to bottom, rgba(233, 243, 245, 0.0), rgba(243, 248, 248, 0.75) 54%, #e9f4f5);
			z-index: 1;
		}
	}

	.integration {
		position: relative;
		// background-image: url('images/img-commit-right.png');
		.img-commits-left-container {
			display: flex;
			align-items: center; /* align vertical */

			img {
				width: 100%;
				max-width: 500px;
				margin: 0 auto;

				@media screen and (min-width: 768px) and (max-width: 1200px) {
					width: 150%;
					margin: 0 -50%;
				}
			}
		}

		.img-commit-right-container {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}


	.opposing-attributes {
		background-color: #e9f4f5;
		text-align: center;
		padding: 60px 0px 100px 0px;

		@media screen and (min-width: 768px) {
			.attributes:first-child {
				padding-right: 40px;
			}

			.attributes:last-child {
				padding-left: 40px;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		@media screen and (max-width: 768px) {
			.image-wrapper {
				padding: 10px 20px;
			}


			img {
				width: 100%;
				height: auto;

				max-width: 400px;
				margin: 0 auto;
			}
		}

		.title-special {
			color: #16536f;
		}

		.section-description {
			font-size: 28px;
			color: #175673;
		}


	}
}
