/*  Home Page
=================*/

main.home {
	color: rgba(13, 15, 26, 0.7);

	.image-google-play {
		cursor: pointer;
		border-radius: 6px;

		transition: transform .3s, box-shadow .3s;

		@media screen and (max-width: 650px) {
			margin-top: 30px;
		}

		@media screen and (min-width: 651px) {
			margin-top: 50px;
		}

		&:hover {
			box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.3);
		}

		&:active {
			transform: translateY(5px);
		}
	}

	.arrow-container {
		@media screen and (min-width: 768px) {
			display: none;
		}
	}


	/*  Intro Section
	===================*/

	.page-section {

		&.intro {
			display: flex;
			position: relative;
			align-items: center;
			text-align: center;
			background-image: url('../images/hero@1x.jpg');
			background: -webkit-image-set( url('../images/hero@1x.jpg') 1x, url('../images/hero@2x.jpg') 2x );
			background-size: cover;
			height: 80vh;

			.centered-text-container {
				max-width: 640px;
			}

			.title,
			.subtitle {
				margin-top: 0px;
			}

			.title {
				margin-bottom: 20px;
				font-size: responsive 32px 72px;
				font-weight: normal;
				line-height: 1.15em;
				color: inherit;

				@media screen and (max-width: 650px) {
					padding: 0px 20px;
				}
			}

			.subtitle {
				max-width: 525px;
				margin: 0 auto;
				font-size: responsive 20px 28px;
				line-height: 1.29em;
				color: rgba(26, 29, 51, 0.7);

				@media screen and (max-width: 650px) {
					padding: 0px 20px;
					max-width: 400px;
				}

				@media screen and (min-width: 651px) and (max-width: 950px) {
					padding: 0px 30px;
				}
			}

			.arrow-container {
				position: absolute;
				bottom: 30px;
				left: 0;
				width: 100%;

				@media screen and (max-width: 650px) {
					bottom: 30px;
				}

				.arrow {
					width: 32px;
					height: 32px;
				}
			}
		}

		/* Generic Item Class
		======================*/

		&.feature-list-item {
			position: relative;
			text-align: center;

			@media screen and (min-width: 768px) {
				padding-top: 50px;
			}

			@media screen and (max-width: 767px) {
				padding: 50px 10px;

				.app-example {
					height: 300px;
					height: 70vmin;
					width: 50vw;

					&, * {
						z-index: -1;
					}

					img {
						height: 100%;
					}
				}
			}

			.info-col {
				display: flex;
				align-content: center;
				justify-content: center;
				flex-direction: column;

				@media screen and (min-width: 768px) {

				}

				@media screen and (max-width: 767px) {
					margin-top: -100px;
					z-index: 3;
				}
			}

			.icon-wrapper {
				display: block;

				@media screen and (min-width: 768px) {
					img {width: 150px; }
				}

				@media screen and (max-width: 767px) {
					img { width: 100px; }

					&.icon-wrapper--left {
						margin-left: 70px;
					}

					&.icon-wrapper--right {
						margin-right: 70px;
					}
				}
			}

			.topic-info-container {
				max-width: 550px;
				padding: 0px 20px;

				@media screen and (max-width: 767px) {
					margin: 0 auto;
				}
			}

			.topic-title {
				font-size: responsive 24px 32px;
				font-weight: 500;
				line-height: 1.16em;
				letter-spacing: 4px;
				color: rgba(38, 43, 77, 0.8);
				margin: 40px 0px 0px 0px;
			}

			.topic-description {
				font-size: responsive 22px 28px;
				line-height: 1.29em;
				margin-top: 15px;
			}

			.row > div {
				@media screen and (min-width: 768px) {
					z-index: 2;
				}
			}
		}

		&.weight,
		&.education,
		&.grocery-list,
		&.ask-an-expert{
			background-color: #faf9f7;

			@media screen and (min-width: 768px) {
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}

		&.exercise,
		&.community,
		&.recipes {
			background-color: #f3f0eb;

			@media screen and (min-width: 768px) {
				padding-top: 200px;
				padding-bottom: 150px;
			}
		}


		/*  Extra Info Banner
		======================*/

		&.feature-extra-info {
			color: rgba(255, 255, 255, 0.9);
			box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
			background-color: #595f7f;

			font-size: responsive 28px 40px;
			font-style: italic;
			font-weight: 500;
			line-height: 1.4em;
			text-align: center;

			p {
				@media screen and (min-width: 768px) {
					padding-left: 70px;
					padding-right: 70px;
				}

				@media screen and (max-width: 767px) {
					padding-left: 48px;
					padding-right: 48px;
				}
			}
		}


		/*  Extra Info Banner
		======================*/

		&.weight,
		&.education {
			padding-bottom: 50px;
		}


		/*  Weight Section
		===================*/

		&.weight {
			.title {
				color: rgba(25, 29, 51, 0.8);
				font-size: responsive 30px 50px;
				font-weight: bold;
				letter-spacing: 2px;
				text-align: center;
				margin-top: 100px;
			}
		}


		/*  Exercise Section
		===================*/

		&.exercise {

		}


		/*  Education Section
		===================*/

		&.education {

		}


		/*  Community Section
		===================*/

		&.community {

		}


		/*  Recipes Section
		===================*/

		&.recipes {

		}


		/*  Utility Styles
		===================*/

		&.skewed-divider {
			@media screen and (min-width: 768px) {
				&:after {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					content: "";
					width: 100%;
					height: 100%;
					background-color: inherit;
					transform: skewY(5deg);
					transform-origin: bottom left;
					z-index: 1;
				}
			}
		}
		&.inverse-skewed-divider {
			@media screen and (min-width: 768px) {
				&:after {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					content: "";
					width: 100%;
					height: 100%;
					background-color: inherit;
					transform: skewY(-5deg);
					transform-origin: bottom right;
					z-index: 1;
				}
			}
		}
	}
}
