//
html {
  font-family: 'Roboto', sans-serif;
}

.title,
.topic-title,
.title-special,
.section-title,
.document-heading,
.feature-extra-info p {
	font-family: 'Raleway', sans-serif;
}

.title-special {
	margin-bottom: 10px;

	span {
		font-family: 'Raleway', sans-serif;
		font-size: responsive 36px 48px;
		font-weight: normal;
	}

	&.title-large {
		span {
			font-size: responsive 48px 64px;
		}
	}

	&.title-small {
		span {
			font-size: responsive 24px 36px;
		}
	}

	width: 100%;
	text-align: center;

	@media screen and (max-width: 767px) {
		margin-bottom: 20px;
	}

	@media screen and (min-width: 768px) {
		letter-spacing: 1px;
		// line-height: 1.4em;

		// span {
		// 	margin-bottom: 20px;
		// }
	}


}

@media screen and (max-width: 767px) {
	// h1.title-special { line-height: 1.25em; }
	// h3.title-special { line-height: 2em; }
}

//
// .responsive-text {
//   // font-size: responsive;
//
//   .page__header {
//     @media screen and (min-width: $screen-sm + 1px) {
//       letter-spacing: 1px;
//     }
//
//     .page__header-title {
//       font-size: responsive 1.50rem 4rem;
//       font-range: $content-min-width $content-max-width;
//     }
//
//     .page__nav {
//       .nav__item {
//         font-size: responsive 1.10rem 1.25rem;
//         font-range: $content-min-width $content-max-width;
//         font-weight: 300;
//       }
//     }
//   }
// }
//
// h1 .title-normal,
// h2 .title-normal,
// h3 .title-normal,
// h4 .title-normal,
// h5 .title-normal,
// h6 .title-normal,
// .h1.title-normal,
// .h2.title-normal,
// .h3.title-normal,
// .h4.title-normal,
// .h5.title-normal,
// .h6.title-normal, {
//   text-transform: uppercase;
// }
//
// h1 small {
//   font-size: responsive 1rem 1.5rem;
// }
//
// .title {
//   font-size: 1.25rem;
// 	font-weight: 600;
// 	color: #8a9399;
// }
//
// .title-normal {
//   color: #57B4EB;
//   font-size: responsive 1rem 1.25rem;
//   font-range: $content-min-width $content-max-width;
//   font-weight: 700;
//   letter-spacing: 0.5px;
// }
//
// .title-light {
//   color: $light-grey;
//   font-size: responsive 1rem 1.25rem;
//   font-range: $content-min-width $content-max-width;
//   font-weight: 300;
//   // text-transform: uppercase;
//   letter-spacing: 0.05em;
// }
//
// .text-light {
//   color: $light-grey;
//   font-size: responsive 0.8rem 1.25rem;
//   font-range: $content-min-width $content-max-width;
//   font-weight: 300;
// }
//
// p.section__description-small {
//   color: #8a9399;
//   font-size: 1.25rem;
//   font-weight: normal;
//   line-height: 1.4;
// }
//
// p.section__description {
//   color: #8a9399;
//   font-size: responsive 1.4rem 2rem;
//   font-range: $content-min-width $content-max-width;
//   font-weight: 400;
//
//   @media screen and (min-width: $screen-md) {
//     line-height: 1.4;
//   }
// }
//
// p.image__aid-large {
//   color: #8a9399;
//   font-size: responsive 1.3rem 2rem;
//   font-range: $content-min-width $content-max-width;
//   font-weight: normal;
//
//   @media screen and (min-width: $screen-md) {
//     line-height: 1.4;
//   }
// }
//
// p.tile__description,
// p.project__description,
// p.body__description {
//   color: $grey;
//   font-size: 1.25rem;
//   font-weight: 400;
//   line-height: 1.5;
// }
//
// button,
// input {
//   font-family: "freight-sans-pro", sans-serif;
// }
//
// /* Font Placeholders
// ========================*/
// //
// // %rem-baseline {
// //   @include rem-baseline(150%);
// // }
// //
// // %font-family {
// //   font-family: 'Open Sans', sans-serif;
// // }
// //
// /* Tag Placeholders
// ========================*/
// //
// // %h1 {
// //   @include rem(font-size, 24px);
// //   font-weight: 400;
// // }
// //
// // %h2 {
// //   @include rem(font-size, 20px);
// //   font-weight: 400;
// // }
// //
// // %h3 {
// //   @include rem(font-size, 18px);
// //   font-weight: 400;
// // }
// //
// // %h4 {
// //   font-weight: 400px;
// // }
// //
// %h5 {
//   font-size: 1.25rem;
//   font-weight: 600;
//   letter-spacing: 0.025em;
// }
//
// // %h6 {
// //   @include rem(font-size, 12px);
// //   font-weight: 400;
// // }
// //
// // %p {
// //   @include rem(font-size, 14px);
// // }
// //
// /* Tag Classes
// ==========================*/
//
// .uppercase {
//   text-transform: uppercase;
// }
//
// // .h1 { @extend %h1; }
// // .h2 { @extend %h2; }
// // .h3 { @extend %h3; }
// // .h4 { @extend %h4; }
// .h5 { @extend %h5; }
// // .h6 { @extend %h6; }
// //
// // .p { @extend %p; }
// //
// /* Tags
// ========================*/
// //
// // html { @extend %rem-baseline; }
// // body { @extend %font-family; }
// // p { @extend %p; }
